<template>
  <div>
    <div class="title">
      <i class="el-icon-back" @click="$router.go(-1)"></i>
    </div>
    <div class="content-view">
      <div
        class="content"
        @click="projectListShow = false"
        v-for="(item, index) in form"
        :key="index"
      >
        <div class="images" @click="uploadClick(index)">
          <div class="img" v-for="(item, i) in item.images" :key="i">
            <img :src="item.fullurl" alt="" />
            <i class="el-icon-circle-close" @click="removeImage(i)"></i>
          </div>
          <el-upload
            v-if="item.images.length < 3"
            class="upload"
            :action="uploadAction"
            :on-success="handleUploadSuccess"
            multiple
            :show-file-list="false"
            :limit="3"
          >
            <i class="el-icon-camera"></i>
          </el-upload>
        </div>
        <el-input
          type="textarea"
          class="textarea"
          :autosize="{ minRows: 3 }"
          placeholder="修改意见"
          v-model="item.opinion"
        ></el-input>
        <i class="el-icon-plus" @click="iconPlus">添加修改意见</i>
      </div>
    </div>
    <div class="bottom">
      <div class="button" @click="confirmSend">确认发送</div>
    </div>
    <div class="project-list" v-show="projectListShow">
      <div
        class="item"
        :class="checkIndex == index ? 'selected' : ''"
        v-for="(item, index) in projectList"
        :key="index"
        @click="
          checkIndex = index;
          project_id = item.id;
        "
      >
        项目[ {{ item.name }} ] 画手[ {{ item.painter_user_id }} ]
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      formIndex: 0,
      checkIndex: 0,
      uploadAction: "",
      projectListShow: false,
      project_id: 0,
      projectList: [],
      img_type: 0,
      form: [{ images: [], opinion: "" }],
    };
  },
  mounted() {
    this.uploadAction = axios.defaults.baseURL + "api/common/upload";
  },
  methods: {
    uploadClick(index) {
      this.formIndex = index;
    },
    iconPlus() {
      this.form.push({ images: [], opinion: "" });
    },
    confirmSend() {
      if (!this.projectListShow) {
        let data = {
          userType: "enterprise",
          is_communication: true,
          is_drawing_type: false,
        };
        this.$api.project.phase_list(data).then((res) => {
          if (!res.data.length) {
            return;
          }
          this.projectListShow = true;
          this.projectList = res.data;
          this.project_id = res.data[0].id;
        });
        return;
      }
      let form = JSON.parse(JSON.stringify(this.form));
      let expMobile = new RegExp(/[1][3,4,5,7,8][0-9]{9}/);
      let expEmail = new RegExp(
        /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
      );
      let expCMobile = false;
      let expCEmail = false;
      form.forEach((item) => {
        let images = [];
        item.images.forEach((image) => {
          images.push(image.url);
        });
        item.images = images.join(",");
        expCMobile = expMobile.test(item.opinion);
        expCEmail = expEmail.test(item.opinion);
      });
      if (expCMobile || expCEmail) {
        this.$message({
          message: "请检查反馈内容是否合规",
          type: "warning",
        });
        return;
      }
      let data = {
        project_id: this.project_id,
        form: form,
      };
      this.$api.mobile_project_opinion.add(data).then(() => {
        this.form = [{ images: [], opinion: "" }];
        this.projectListShow = false;
      });
    },
    handleUploadSuccess(res) {
      this.form[this.formIndex].images.push(res.data);
    },
    removeImage(index) {
      this.form[this.formIndex].images.splice(index, 1);
    },
  },
};
</script>

<style>
.el-upload {
  padding: 45px 0;
  width: 100%;
}
</style>
<style lang="less" scoped>
.content-view {
  height: 80vh;
  overflow: auto;
}
.title {
  text-align: left;
  font-size: 25px;
  padding: 30px 25px;
  color: #ffffff;
}
.content {
  padding: 0 25px;
  text-align: left;
  .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 8px;
    .upload {
      background: #f6f6f9;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 29%;
    }
    .el-icon-camera {
      color: #dad9e2;
      font-size: 23px;
    }
    .img {
      width: 29%;
      position: relative;
      padding: 7px 7px 0 0;
      img {
        width: 100%;
      }
      .el-icon-circle-close {
        position: absolute;
        top: 0;
        right: 0;
        color: #ff1d1d;
        font-size: 17px;
      }
    }
  }
  .textarea {
    margin-top: 10px;
  }
  .el-icon-plus {
    font-size: 17px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    margin-top: 10px;
  }
}
.bottom {
  position: fixed;
  bottom: 26px;
  width: 100%;
  .button {
    width: 243px;
    background: #0083ff;
    box-shadow: 0px 2px 8px 0px rgba(0, 131, 255, 0.44);
    border-radius: 110px;
    padding: 15px 0;
    font-size: 14px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
    line-height: 12px;
    margin: 0 auto;
  }
}

.project-list {
  background: #ffffff;
  box-shadow: 0px 0px 11px 0px rgba(31, 139, 225, 0.2);
  border-radius: 22px;
  width: calc(100% - 50px);
  position: fixed;
  bottom: 100px;
  margin: 0 25px;
  max-height: 289px;
  overflow: auto;
  padding: 0 19px;
  box-sizing: border-box;
  .item {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    padding: 28px 0;
    border-bottom: 1px solid #e6e6e6;
  }
  .selected {
    font-weight: bold;
    color: #0083ff;
  }
}
</style>